@import 'global/constants';

.nav-bar {
    @include background-dark;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: $nav-bar-width;
    padding: 0;
    margin: 0;
    z-index: 9999;

    .container {
        position: relative;
        padding: 0;
        margin: 0;
        height: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;

        .logo {
            position: relative;
            width: $nav-bar-width;
            height: $nav-bar-width;
            padding: 10px;
            flex-shrink: 0;
        }

        .top, .bottom {
            a.active > .nav-item {
                background-color: $navbar-background;
            }

            .unread {
                display: block;
                position: relative;

                &::after {
                    $size: 8px;
                    content: '';
                    display: block;
                    background-color: $red;
                    width: $size;
                    height: $size;
                    border-radius: $size;
                    position: absolute;
                    bottom: 14px;
                    right: 14px;
                }
            }

            .nav-item {
                position: relative;
                width: $nav-bar-width;
                height: $nav-bar-width;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;

                p {
                    display: block;
                    width: calc(100% - 4px);
                    text-align: center;
                    padding: 0;
                    margin: 0 2px;
                    font-size: 8pt;
                }

                svg {
                    color: $light-border;
                    display: block;
                    width: 100%;
                    height: 23px;
                    margin: 0;
                    padding: 0;
                }

                &:hover {
                    background-color: transparentize($color: $navbar-background, $amount: .5);
                    cursor: pointer;

                    .simple-tooltip {
                        visibility: visible;
                    }
                }
            }
        }

        .top {
            justify-content: flex-start;
        }

        .bottom {
            justify-content: flex-end;
            margin-bottom: 60px;
        }
    }
}
