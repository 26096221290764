@import "global/constants";

.filter-form {
    display: inline-flex;
    margin-left: auto;
    margin-right: 0;
    margin-bottom: 24px;

    &.main-page {
        margin-left: 0;
        margin-right: auto;
    }

    div:not(:last-child) {
        margin-right: 12px;
    }

    .not-selected {
        color: $darkGrey;
    }

    .picker {
        height: 32px;
        min-height: unset;
        background: $white;
        border: 1px solid $light-border;
        box-sizing: border-box;
        border-radius: 4px;
        width: inherit;
        font-size: 12px;
        line-height: 16px;
        display: flex;
        align-items: center;
    }

    .bold-placeholder {
        .ant-picker-input {
            input {
                color: $text-dark;
                font-weight: 600;
            }
        }
    }

    label {
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
        display: flex;
        align-items: flex-end;
        color: $background-dark;
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        background-color: transparent;
        border: 0;
        border-radius: 2px;
    }

    .ant-select-selection-placeholder {
        color: $darkGrey;
        font-size: 12px;
        line-height: 16px;
        font-weight: 600;
    }

    .ant-select-arrow {
        color: $black;
        font-weight: 600;
    }

    .ant-select-selection-item {
        font-weight: 600;
    }

    input, select {
        height: 32px;
        background: $white;
        border-top: 1px solid $light-border;
        border-bottom: 1px solid $light-border;
        box-sizing: border-box;
        border-radius: 0;
        width: inherit;
        font-size: 12px;
        line-height: 16px;
        display: flex;
        align-items: center;
        color: $darkGrey;

        &:focus {
            border-top: 1px solid $light-border;
            border-bottom: 1px solid $light-border;
            box-sizing: border-box;
            border-radius: 0;
            color: $black;
        }

        &:hover {
            border-top: 1px solid $light-border;
            border-bottom: 1px solid $light-border;
            box-sizing: border-box;
            border-radius: 0;
        }

        .arrow-icon {
            position: absolute;
            right: 5px;
            top: 10px;
        }
    }

    .issue-type-select-div {
        width: 146px;

        .issue-type-select-picker {
            position: relative;
            display: block;

            .picker-label {
                display: block;
                font-weight: 600;
                margin-left: 10px;
                margin-top: 7px;
                cursor: pointer;

                &.checked {
                    color: $black;
                }

                .multiple-types {
                    display: flex;
                    vertical-align: middle;
                    align-items: center;
                }

                svg {
                    margin-right: 5px;
                    margin-left: 5px;
                }

                .arrow-icon {
                    position: absolute;
                    right: 5px;
                    top: 9px;
                }
            }

            .issue-type-drop-menu {
                position: absolute;
                min-width: 160px;
                z-index: 100;
                top: 35px;
                right: 0;
                padding: 4px;
                margin: 0;
                background: $white;
                border: 1px solid $light-border;
                box-sizing: border-box;
                border-radius: 4px;

                &.shown {
                    display: block;
                }

                &.hidden {
                    display: none;
                }

                .issue-type-picker-item {
                    display: flex;
                    vertical-align: middle;
                    align-items: center;
                    position: relative;
                    line-height: 0;
                    padding: 8px;
                    margin: 0;
                    height: 32px;
                    box-sizing: border-box;
                    font-size: 12px;
                    font-weight: 600;
                    cursor: pointer;

                    input[type=checkbox] {
                        display: none;
                        cursor: pointer;

                        + label:before {
                            font-family: FontAwesome;
                            display: inline-block;
                            content: "\f0c8";
                            color: $grey;
                            letter-spacing: 10px;
                        }

                        &:checked + label:before {
                            content: "\f14a";
                            color: $darkGrey;
                            letter-spacing: 10px;
                        }
                    }

                    label {
                        cursor: pointer;
                        margin-left: 8px;
                        margin-bottom: 0;
                        font-size: 14px;
                        font-weight: 400;
                    }

                    .selected-item {
                        font-weight: 600;
                    }

                    &:hover {
                        background-color: $background-light;
                    }
                }
            }
        }

        .ant-select-clear {
            position: absolute;
            right: 11px;
            top: 13px;
        }

        .ant-select-arrow {
            margin-top: -7px;
        }
    }
}

.issue-type-drop-menu {
    min-width: 160px;
    font-weight: 600;
    background: $white;
    border: 1px solid $light-border;
    box-sizing: border-box;
    border-radius: 4px;

    &.shown {
        display: block;
    }

    &.hidden {
        display: none;
    }
}
