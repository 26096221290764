.trailer-settings-container {

    .temp-info-icon {
        color: #D0D2DD;
    }

    .tooltip-container {
        display: inline-block;
        cursor: pointer;

        .simple-tooltip {
            line-height: 18px !important;
        }
    }

    .tooltip-container:hover {
        .temp-info-icon {
            color: #030D1D;
        }

        .simple-tooltip {
            visibility: visible;
        }
    }

    .measurement-type {
        &.temperature {
            margin-bottom: 16px;
        }
    }

    .measurements {
        .left-padding {
            padding-left: 48px;
        }
    }
}
