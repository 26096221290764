@import "global/constants";

.statistics {
    .breadcrumb {
        background-color: transparent;
        font-weight: bold;
        font-size: 11px;
        line-height: 14px;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        margin-top: 11px;
        padding: 0.75rem 0 0 0;
        margin-bottom: 0;

        .breadcrumb-item a {
            color: $darkGrey;
        }

        .active a {
            color: $black;
        }

        .breadcrumb-item + .breadcrumb-item::before {
            content: ">";
        }
    }

    .heading {
        font-weight: 600;
        font-size: 36px;
        line-height: 47px;
        margin-top: 8px;
        display: flex;
        align-items: center;
        margin-bottom: 24px;

        .loader {
            margin-left: 8px;
        }
    }

    .btn-white {
        background: $white;
        border: 1px solid $light-border;
        border-radius: 2px;
        padding: 9px 16px;
        font-size: 11px;
        line-height: 14px;
        font-weight: bold;
        letter-spacing: 0.08em;

        &:hover:enabled {
            border-color: $darkGrey;
        }

        &:active, &:focus {
            box-shadow: none;
        }
    }

    .members {
        padding-bottom: 32px;
    }

    .stats-preview-button-group-container {
        align-self: center;

        .stats-preview-button-group {
            display: flex;
            justify-content: flex-end;
        
            .stats-btn {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 2px 16px 2px 16px;
                gap: 6px;
        
                height: 32px;
        
                font-style: normal;
                font-weight: 700;
                font-size: 11px;
                line-height: 14px;
                letter-spacing: 0.08em;
                text-transform: uppercase;
        
                border-radius: 2px;
        
                &.settings-button {
                    margin-right: 17px;
                    background: $white;
                    border-color: $reefer-neutral-400;
                    color: $neutral-00;
        
                    .settings-icon {
                        height: 12px;
                        width: 12px;
                    }
                }
        
                &.share-button {
                    background: $green;
                    border-color: $green;
                    color: $white;
        
                    &:hover {
                        background: $green;
                        border-color: $green;
                        color: $white;
                    }
        
                    .share-icon {
                        height: 12px;
                        width: 12px;
                    }
                }
            }
        }
    }
}

.stats-header {
    background: $white;
    border: 1px solid $light-border;
    border-top: 4px solid $darkest-gray;
    border-radius: 4px;
    margin-bottom: 28px;
    padding: 20px 48px;

    .issue-count-heading {
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: $darkGrey;
    }

    .issue-count {
        font-weight: 600;
        font-size: 20px;
        line-height: 26px;
        margin-top: 8px;

        @media (max-width: 991px) {
            font-size: 20px;
            line-height: 26px;
        }

        .link-icon {
            color: $chart-color-4;
            height: 16px;
            margin-left: 4px;
            cursor: pointer;

            &:hover {
                color: $darkest-gray;
            }
        }

        .time-unit {
            font-size: 12px;
            line-height: 16px;
            margin-right: 8px;
            margin-left: 2px;
        }
    }

    .grid-divider {
        position: relative;

        [class*="col"]:nth-child(n + 2):after {
            content: "";
            background-color: $grey;
            position: absolute;
            top: 0;
            bottom: 0;
            left: -1px;
            padding: 0;
            margin-left: -40px;
            width: 1px;
            height: auto;

            @media (max-width: 1199px) {
                display: none;
            }
        }
    }

    &.user-stats-header {
        border-top-color: $blue;
    }
}

.chart-card {
    background: $white;
    border: 1px solid $light-border;
    border-radius: 4px;
    height: 582px;
    padding-right: 0;
    padding-left: 0;
    margin-bottom: 36px;
}

.tooltip-container {
    position: relative;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    display: inline-block;
    cursor: pointer;
    margin-left: 4px;

    .icon {
        color: $chart-color-4;
    }

    &:hover {
        .simple-tooltip {
            visibility: visible;
        }

        .icon {
            color: $text-dark;
        }
    }
}
