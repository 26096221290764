@import "global/constants";

.dashboard {
    @include page;

    .page-header {
        .page-title {
            padding-left: 7px;
            margin-top: 24px;
            margin-bottom: 0;
    
            div, svg {
                display: inline;
            }
        }

        .page-subtitle {
            font-size: 15px;
            font-weight: 500;
            color: $darkGrey;

            span {
                font-weight: 700;
                color: $background-dark;
            }

            .link-icon {
                color: $darkGrey;
                font-weight: 900;
                font-size: 12px;
                line-height: 12px;
                margin-left: 4px;
                cursor: pointer;
            }
            
            .link-icon:hover {
                color: $darkest-gray;
            }
        }
    }
    

    .tooltip-container {
        font-size: 12px;
        font-weight: 400;
        position: relative;
        margin-left: 7px;
        display: inline-block;
        cursor: pointer;
        max-height: 12px;
        text-transform: none;

        .simple-tooltip {
            line-height: 18px;
        }

        .tooltip-info-icon {
            font-size: 12px;
            color: $grey;
        }
    }

    .tooltip-container:hover {
        .tooltip-info-icon {
            color: $darkest-gray;
        }

        .simple-tooltip {
            visibility: visible;

        }
    }
    

    .page-subtitle {
        padding-left: 8px;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 16px;
        color: #030D1D;

        span {
            color: $header-border-color;
        }
    }

    .issues {
        display: flex;
        flex-wrap: wrap;
        
        [class*="col-"] {
            padding-left: 8px;
            padding-right: 8px;
        }
    }
}

.modal-open .dashboard {
    -webkit-filter: blur(3px);
    -moz-filter: blur(3px);
    -o-filter: blur(3px);
    -ms-filter: blur(3px);
    filter: blur(3px);
}
