// Sizes

$nav-bar-width: 60px;

// Colors

$white: white;
$black: black;
$background-light: #f4f5fa;
$background-dark: #172437;
$background-image-dark: linear-gradient(180deg, $background-dark 6.67%, #030D1D 100%);
$circle-background: #bbb;
$notification-background-light: #FCFEFE;
$darkest-gray: #030d1d;
$navbar-background: #2e486e;
$light-border: #E2E5E9;
$table-header-color: #E8EBF3;
$header-border-color: #D0D2DD;
$light-input-background-color: #FDF6F6;
$disabled-snooze-color: #DCDEEA;
$green: #19bd8c;
$light-green: #E8F8F3;
$red: #d3424e;
$light-red: #FBF0F1;
$grey: #d0d2dd;
$darkGrey: #828A95;
$paleGrey: #cbcbcb;
$lightGrey: #E3E7EB;
$orange: #F27C46;
$blue: #289AC2;
$reefer-info-400: #56CCF2;
$text-dark: #030D1D;
$chart-color-1: #172437;
$chart-color-2: #7B838E;
$chart-color-3: #BDC1C7;
$chart-color-4: #D0D2DD;
$blue-chart-color-1: #289AC2;
$blue-chart-color-2: #9AD5EA;
$neutral-00: #172437;
$reefer-neutral-400: #E2E5E9;
$reefer-neutral-600: #BDC1C7;
$reefer-primary-300: #030D1D;
$reefer-info-200: #EEF7FA;
$reefer-info-300: #9FD2E4;
$reefer-info-500: #289AC2;

$brand-black-01: #0F2025;
$prs-blue: #2371F1;

// Mixins

.link {
    text-decoration: underline;
    cursor: pointer;
}

.greenColorText {
    color: $green;
}

.redColorText {
    color: $red;
}

.greyColorText {
    color: $darkGrey;
}

.orangeColorText {
    color: $orange;
}

.blueColorText {
    color: $blue;
}

.bold {
    font-weight: 700;
}

.italic {
    font-style: italic;
}

.centerAlign {
    display: flex;
    align-items: center;
}

@mixin background-dark {
    background-color: $background-dark;
    background-image: $background-image-dark;
    color: $white;
}

@mixin page {
    .page-title {
        font-style: normal;
        font-weight: 600;
        font-size: 36px;
        line-height: 47px;
        margin: 24px 0;
        color: #030D1D;
    }
}

.minSortWidth {
    min-width: 100px;
}

svg {
    display: block;
}

:export {
    blue: $blue;
    blueChartColor1: $blue-chart-color-1;
    blueChartColor2: $blue-chart-color-2;
    chartColor1: $chart-color-1;
    chartColor2: $chart-color-2;
    chartColor3: $chart-color-3;
    chartColor4: $chart-color-4;
    darkGrey: $darkGrey;
    disabled: $background-light;
    lightBorder: $light-border;
    white: $white;
}
