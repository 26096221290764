@import "global/constants";

.power-issues {

    @media only screen and (max-width: 992px) {
        margin: 2%;
    }

    .breadcrumb {
      background-color: transparent;
      font-style: normal;
      font-weight: bold;
      font-size: 11px;
      line-height: 14px;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      margin-top: 11px;
      .breadcrumb-item a {
        color: $darkGrey;
      }
      .active a{
        color: $black;
      }
      .breadcrumb-item + .breadcrumb-item::before {
        content: ">";
      }
      .breadcrumb-item + .breadcrumb-item:active::after {
        content: "<<";
      }
    }

    .heading-div {
        display: inline-block;
        width: 100%;

        .heading {
            float: left;
            font-style: normal;
            font-weight: 600;
            font-size: 36px;
            line-height: 47px;
            color: $darkest-gray;
            padding-left: 1rem;
        }

        div, svg {
            display: inline;
        }
    }

    .active-toggle {
        float: right;
        margin-bottom: 5px;
    }

    .power-issues-div {
        width: 100%;
        .dt-align-right {
            text-align: right;
        }

        .dt-align-left {
            text-align: left;
        }

        .dt-align-center {
            text-align: center;
        }

        .table{
            th {
                background-color: $table-header-color;
                border-top: 1px solid $grey;
                border-right: 1px solid $grey;

                &:first-child {
                    border-left: 1px solid $header-border-color;
                }
            }

            tbody {
                .notifications {
                    .notification-level, .snoozed-info, .notification-info {
                        display: inline;
                    }
                }

                .snooze-icon {
                    width: 16px;
                }

                .snooze-icon, .seen-icon, .info-icon, .comment-icon, .resolve-icon {
                    cursor: pointer;
                }

                .snooze-icon.medium, .comment-icon.medium {
                    color: $darkGrey;
                }

                .snooze-icon.medium:hover, .disabled-icon-snooze.light:hover, .disabled-icon:hover, .comment-icon.light:hover, .comment-icon.medium:hover, .resolve-icon:hover {
                    color: $background-dark;
                    fill: $background-dark;
                }

                .disabled-icon-snooze.light, .comment-icon.light, .resolve-icon {
                    color: $grey;
                }

                .disabled-icon>path {
                    color: $disabled-snooze-color;
                    fill: $disabled-snooze-color;
                }

                .disabled-icon:hover>path {
                    fill: $background-dark;
                }

                tr {
                    height: 40px;
                    border: 1px solid $light-border;
                    border-bottom: none;
                    box-sizing: border-box;

                    &:nth-of-type(odd) {
                    background: $white;
                    }

                    &:nth-of-type(even) {
                    background: $background-light;
                    }
                }

                tr:hover {
                    background-color: $table-header-color;
                    box-shadow: 0px 4px 12px -4px $light-border;

                    td {
                        border-top-color: $header-border-color;
                        border-bottom-color: $header-border-color;

                        &:first-child {
                            border-left-color: $header-border-color;
                        }

                        &:last-child {
                            border-right-color: $header-border-color;
                        }
                    }
                }
            }
            .resolvedContainer {
                flex: 1;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
            }
        }
    }
}

.modal-open .power-issues {
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  -o-filter: blur(3px);
  -ms-filter: blur(3px);
  filter: blur(3px);
}