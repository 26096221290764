@import "global/constants";

.chip {
    display: flex;
    align-items: center;
    width: fit-content;
    height: 20px;
    padding: 2px 4px;
    background-color: $table-header-color;
    border: 1px solid $grey;
    border-radius: 4px;
    white-space: nowrap;

    .chip-icon {
        color: $darkGrey;
        margin-right: 5px;
    }

    .remove-icon {
        color: $darkGrey;
        margin-right: -4px;
        padding: 4px 5px;
        margin-left: 2px;
    }
}
