@import "global/constants.scss";

.custom-dropdown {
    .btn-custom {
        color: $background-dark;
        background: $white;
        font-size: 11px;
        line-height: 14px;
        font-weight: 700;
        border: 1px solid $light-border;
        border-radius: 2px;
        padding: 4px 16px;

        &:hover:enabled, &:focus, &:active {
            background: $white;
            border-color: $grey;
            box-shadow: none;
        }

        &::after {
            display: none;
        }
    }

    &.show {
        .btn-custom {
            border-color: $darkGrey;

            &:hover {
                border-color: $darkGrey;
            }
        }

        .dropdown-menu {
            border: 1px solid $light-border;
            border-radius: 4px;
            padding: 4px;

            .dropdown-item {
                cursor: pointer;
                padding: 12px;
                font-size: 12px;
                font-weight: 500;
                line-height: 16px;

                &:hover, &:active {
                    color: initial;
                    font-weight: 700;
                    background-color: $background-light;
                }
            }
        }
    }
}
