@import "global/constants";

.notifications {

    @media only screen and (max-width: 992px) {
      margin: 2%;
    }

    .breadcrumb {
      background-color: transparent;
      font-style: normal;
      font-weight: bold;
      font-size: 11px;
      line-height: 14px;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      margin-top: 11px;

      .breadcrumb-item a {
        color: $black;
      }

      .breadcrumb-item + .breadcrumb-item::before {
        content: ">";
      }
      .breadcrumb-item + .breadcrumb-item:active::after {
        content: "<<";
      }
    }

    .heading {
      font-style: normal;
      font-weight: 600;
      font-size: 36px;
      line-height: 47px;
      color: $darkest-gray;
      padding-left: 1rem;

      div, svg {
        display: inline;
      }
    }
    
    .notification-history-div {
        width: 100%;

        .dt-align-right {
            text-align: right;
        }

        .dt-align-left {
            text-align: left;
        }

        .dt-align-center {
            text-align: center;
        }

        .table{
            th {
              background-color: $table-header-color;
              border-top: 1px solid $grey;
              border-right: 1px solid $grey;
            }

            tbody {
                tr {
                    height: 40px;
                    border: 1px solid $light-border;
                    border-bottom: none;
                    box-sizing: border-box;

                    &:nth-of-type(odd) {
                    background: $white;
                    }
                    &:nth-of-type(even) {
                    background: $background-light;
                    }
                }
            }
        }
    }
}

.modal-open .notifications {
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  -o-filter: blur(3px);
  -ms-filter: blur(3px);
  filter: blur(3px);
}
