@import "global/constants";

.snooze-issue-reasons {
    .row {
        margin-bottom: 12px;

        .col {
            text-align: right;

            .btn-continue {
                background-color: $green;
                color: $white;
                font-style: normal;
                font-size: 11px;
                font-weight: 700;
                line-height: 14px;
                align-items: center;
                letter-spacing: 0.08em;
                width: 150px;
                height: 36px;
                border-radius: 2px;

                .add-icon {
                    margin-right: 5px;
                }
            }
        }
    }

    .reasons-div {
        .dt-align-left {
            text-align: left;
        }

        .dt-align-right {
            text-align: right;
            padding-right: 7vw;
        }

        .dt-align-center {
            text-align: center;
        }

        .reason {
            max-width: 70vw;
            overflow-x: hidden;
            text-overflow: ellipsis;

            .note {
                color: $darkGrey;
                margin-left: 5px;
            }
        }

        .actions {
            color: $grey;

            svg {
                cursor: pointer;
                margin: 0 6px;

                &:hover {
                    color: $background-dark;
                }
            }
        }
    }
}
