@import 'global/constants';

.modal {
    .add-trailers-modal {
        .modal-content {
            width: 415px;
            margin: auto;

            .modal-container {
                margin-bottom: 0;
                padding: 30px 24px 24px 24px;

                .title {
                    text-transform: uppercase;
                }

                .description {
                    font-size: 12px;
                    line-height: 16px;
                    color: $darkest-gray;
                    margin-bottom: 2px;
                }

                .delimiter-select {
                    display: flex;
                    flex-direction: column;
                    
                    small {
                        display: flex;
                        margin-bottom: 2px;
                        font-size: 12px;
                        font-weight: 700;

                        label {
                            margin-right: 2px;
                            margin-bottom: 0;
                        }

                        .tooltip-container {
                            position: relative;
                            margin-right: 4px;
                            display: inline-block;
                            cursor: pointer;
            
                            .simple-tooltip {
                                line-height: 18px;
                                font-weight: 500;
                            }
            
                            .tooltip-info-icon {
                                font-size: 12px;
                                color: $grey;
                            }
                        }
        
                        .tooltip-container:hover {
                            .tooltip-info-icon {
                                color: $darkest-gray;
                            }
            
                            .simple-tooltip {
                                visibility: visible;
                            }
                        }
                    }
                }

                .upload-radio {
                    display: flex;
                    margin-bottom: 20px;
                    
                    .radio-group {
                        padding-top: 12px;
                        padding-right: 20px;

                        input {
                            margin-right: 4px;
                        }

                        label {
                            margin-bottom: 0;
                        }
                    }
                }

                .trailer-section {
                    margin-top: 0;
                    .heading {
                        font-size: 12px;
                        line-height: 16px;
                        margin-top: 12px;
                        margin-bottom: 4px;
                    }

                    .trailer-input-section {
                        display: flex;
                        justify-content: space-between;

                        .trailer-input {
                            margin-right: 12px;
                        }
    
                        .editable {
                            flex: 1;
                            width: 80px;
                            border: none;
                            background-color: transparent;
                            outline: none;
                            padding: 2px 5px;
                            margin: 3px;
                            font-size: 12px;
                        }

                        div {
                            width: 100%;
                        }

                        .trailer-box {
                            display: flex;
                            flex-wrap: wrap;
                            border: 1px solid $header-border-color;
                            box-sizing: border-box;
                            border-radius: 4px;
                            padding: 2px 4px;
                            font-style: normal;
                            font-size: 12px;
                            line-height: 16px;
                            margin-bottom: 4px;
    
                            .button-container {
                                word-break: break-all;
                            }
                        }

                        .disabled {
                            border-color: $darkGrey;
                            background-color: $paleGrey;

                            input {
                                color: $black;
                            }
                        }

                        .error {
                            border-color: rgba(211, 66, 78, 0.4);
                        }

                        .heading:after {
                            content:"*";
                            color: red;
                        }
                    }

                    .message-content {
                        margin-bottom: 0;
                        padding-bottom: 0;
                        display: flex;
                        justify-content: space-between;
                    }

                    .message-card {
                        margin-bottom: 5px;
                        display: flex;
                    }

                    .length-paragraph {
                        font-size: 12px;
                        line-height: 16px;
                        color: $darkest-gray;
                        margin-left: auto;
                        order: 2;
                        padding-bottom: 0;
                        margin-bottom: 10px;
                    }

                    .error-message {
                        margin: 0;
                        font-size: 12px;
                        line-height: 16px;
                        color: $red;
                    }

                    .fleet-select {
                        margin-bottom: 24px;
                    }
                }

                .buttons {
                    text-align: right;
                    display: block;

                    .btn {
                        float: none;
                    }

                    .cancel-button, .submit-button {
                        display: inline;
                        height: 32px;
                        width: 80px;
                        text-transform: uppercase;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 11px;
                        line-height: 14px;
                        padding: 0;
                        border-radius: 2px;

                        &:disabled {
                            background: $grey;
                            color: $darkGrey;
                        }
                    }

                    .btn-continue {
                        margin-right: 0;
                    }

                    .cancel-button {
                        margin-right: 10px;
                        background-color: $white;
                        border-color: $light-border;
                    }
                }
            }
        }
    }
}
