@import 'global/constants';

.fleet-overview {
    .breadcrumb {
        background-color: transparent;
        font-style: normal;
        font-weight: bold;
        font-size: 11px;
        line-height: 14px;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        margin-top: 11px;

        .breadcrumb-item a {
            color: $darkGrey;
        }

        .active a{
            color: $black;
        }

        .breadcrumb-item + .breadcrumb-item::before {
            content: ">";
        }

        .breadcrumb-item + .breadcrumb-item:active::after {
            content: "<<";
        }

        .breadcrumb-item:active::after {
            content: "<<";
        }

        .svg-icon {
            position: relative;
            top: 5px;
            left: 2px;
        }
    }

    .heading {
        font-style: normal;
        font-weight: 600;
        font-size: 36px;
        line-height: 47px;
        color: $darkest-gray;
        padding-left: 1rem;

        div, svg {
            display: inline;
        }

        .total-trailers {
            font-size: 20px;
            font-weight: 700;
            color: $darkGrey;
        }
    }

    .fleet-actions {
        display: flex;

        .fleet-buttons {
            display: flex;
            margin-left: auto;

            .trailers-button {
                margin-bottom: 11px;
                margin-left: 12px;
                padding: 1px;
                color: #fff;
                border-radius: 2px;
                outline: none;
                border: none transparent;
                font-style: normal;
                font-weight: bold;
                text-transform: uppercase;
                font-size: 12px;
                line-height: 14px;

                .icon-trailers {
                    margin-right: 4px;
                    padding-top: 1px;
                }

                &.create {
                    width: 115px;
                    height: 32px;
                    background-color: $green;

                    &:hover {
                        color: $white;
                    }
                }
            }
        }
    }

    .fleetTableDiv {
        width: 100%;

        .dt-align-right {
            text-align: right;
        }

        .dt-align-center {
            text-align: center;
        }

        .table{
            th {
                background-color: $table-header-color;
                border-top: 1px solid $grey;
                border-right: 1px solid $grey;
            }

            .tooltip-container {
                position: relative;
                margin-left: 5px;
                display: inline-block;
                cursor: pointer;

                .simple-tooltip {
                    line-height: 18px;
                }

                .tooltip-info-icon {
                    font-size: 12px;
                    color: $grey;
                }
            }

            .tooltip-container:hover {
                .tooltip-info-icon {
                    color: $darkest-gray;
                }

                .simple-tooltip {
                    visibility: visible;
                }
            }

            .fleet-cell {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            .edit-icon {
                cursor: pointer;
                color: $grey;
            }

            .action-icon {
                margin-right: 7px;
                margin-left: 7px;
                color: $grey;

                &:hover {
                    color: $darkest-gray;
                }
            }

            .btn-continue {
                padding: 4px 16px;
                background-color: $green;
                color: $white;
                font-size: 11px;
                font-weight: 700;
                letter-spacing: 0.08em;
                line-height: 14px;
                height: 24px;
                border-radius: 2px;

                .redo-icon {
                    transform: scaleX(-1);
                    margin-right: 4px;
                }
            }
        }

        tr {
            height: 40px;
            border: 1px solid $light-border;
            border-bottom: none;
            box-sizing: border-box;

            &:nth-of-type(odd) {
                background: $white;
            }

            &:nth-of-type(even) {
                background: $background-light;
            }
        }

        tr:hover {
            background-color: $table-header-color;
            box-shadow: 0px 4px 12px -4px $light-border;

            td {
                border-top-color: $header-border-color;
                border-bottom-color: $header-border-color;

                &:first-child {
                    border-left-color: $header-border-color;
                }

                &:last-child {
                    border-right-color: $header-border-color;
                }
            }
        }
    }
}
