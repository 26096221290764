@import "global/constants";

.custom-table {
    width: 100%;
    border-spacing: 0;
    color: $darkest-gray;
    font-size: 12px;
    line-height: 16px;

    &.full {
        .table-row:nth-of-type(odd):not(:hover) {
            background-color: $background-light;
        }

        .cell {
            border: 1px solid $light-border;
        }

        .header-cell {
            background-color: $table-header-color;
            border-color: $header-border-color;
            border-bottom: none;
        }
    }

    &.compact {
        .table-row {
            border-bottom: 1px solid $light-border;
        }
    }

    .table-row {
        background-color: $white;

        &:not(.table-header-row):hover {
            background-color: $table-header-color
        }

        .cell {
            padding: 10px 8px;

            &.left {
                text-align: left;
            }

            &.center {
                text-align: center;
            }

            &.right {
                text-align: right;
            }
        }

        .header-cell {
            white-space: nowrap;

            &.clickable {
                cursor: pointer;
            }

            .sort-icon {
                margin-left: 4px;

                .fa-primary,
                .fa-secondary {
                    opacity: 1;
                    fill: $grey;
                }

                &.asc {
                    .fa-primary {
                        fill: $background-dark;
                    }
                }

                &.desc {
                    .fa-secondary {
                        fill: $background-dark;
                    }
                }
            }
        }

        .data-cell {
            word-break: break-word;
        }
    }
}
