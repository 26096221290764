@import "global/constants";

.group-members {
    width: 100%;

    .dt-align-left {
        text-align: left;
    }

    .dt-align-center {
        text-align: center;
    }

    .name, .email {
        max-width: 40vw;
        overflow-x: hidden;
        text-overflow: ellipsis;
    }

    .action {
        color: $disabled-snooze-color;

        .delete-icon {
            cursor: pointer;

            &:hover {
                color: $background-dark;
                fill: $background-dark;
            }
        }
    }

    .add-container {
        cursor: pointer;
        color: $blue;
        font-size: 12px;
        line-height: 16px;
        padding-left: 8px;
        font-weight: bold;
    }

    .no-members-container {
        display: flex;
        flex-direction: column;
        align-items: center;

        .user-icon {
            font-size: 24px;
            line-height: 28px;
            color: $grey;
        }

        .no-member-title {
            font-weight: bold;
            font-size: 15px;
            line-height: 20px;
            color: $darkGrey;
            margin-top: 6px;
            margin-bottom: 10px;
        }

        .btn-continue {
            background-color: $green;
            color: $white;
            font-style: normal;
            font-size: 11px;
            font-weight: 700;
            line-height: 14px;
            align-items: center;
            letter-spacing: 0.08em;
            width: 157px;
            height: 36px;
            border-radius: 2px;
            margin-right: 0;
    
            .add-icon {
                margin-right: 5px;
            }
        }
    }
}
