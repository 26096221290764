@import "global/constants";

.issues {
    .breadcrumb {
        background-color: transparent;
        font-weight: bold;
        font-size: 11px;
        line-height: 14px;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        margin-top: 11px;
        padding: 0.75rem 0 0 0;
        margin-bottom: 0;

        .breadcrumb-item a {
            color: $darkGrey;
        }

        .active a {
            color: $black;
        }

        .breadcrumb-item + .breadcrumb-item::before {
            content: ">";
        }
    }

    .heading {
        font-weight: 600;
        font-size: 36px;
        line-height: 47px;
        margin-top: 8px;
        display: flex;
        align-items: center;
        margin-bottom: 24px;

        .loader {
            margin-left: 8px;
        }
    }

    .btn-white {
        background: $white;
        border: 1px solid $light-border;
        border-radius: 2px;
        padding: 9px 16px;
        font-size: 11px;
        line-height: 14px;
        font-weight: bold;
        letter-spacing: 0.08em;

        &:hover:enabled {
            border-color: $darkGrey;
        }

        &:active, &:focus {
            box-shadow: none;
        }
    }

    .members {
        padding-bottom: 32px;
    }

    .link {
        margin-bottom: 0;
    }

    .grayColorText {
        color: $darkGrey;
    }
}
