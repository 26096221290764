@import "global/constants";

.user-management {
    padding-top: 8px;
    color: $darkest-gray;
    padding-bottom: 20px;

    .header-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 12px;

        .header {
            display: flex;
            align-items: center;
            font-weight: bold;
            font-size: 20px;
            line-height: 26px;
            background-color: transparent;

            .loader {
                margin-left: 6px;
            }
        }

        .search-input-container {
            position: relative;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;

            .search-input {
                padding: 8px 24px 8px 8px;
                border: 1px solid $grey;
                border-radius: 4px;
                margin-right: 16px;
                width: 263px;
                height: 32px;
                outline: none;
            }

            .search-icon {
                padding: 10px 8px;
                cursor: pointer;
                position: absolute;
                transform: translateY(-50%);
                right: 16px;
                top: 50%;
            }
        }
    }

    .btn-continue {
        background-color: $green;
        color: $white;
        font-style: normal;
        font-size: 11px;
        font-weight: 700;
        line-height: 14px;
        align-items: center;
        letter-spacing: 0.08em;
        width: 150px;
        height: 32px;
        border-radius: 2px;

        .add-icon {
            margin-right: 5px;
        }
    }

    .group-chip-container {
        display: flex;
        flex-wrap: wrap;
        gap: 4px;
    }

    .actions-dropdown {
        .dropdown-toggle {
            color: $darkGrey;
            background: none;
            border: none;
            padding: 0;

            &:hover:enabled,
            &:focus {
                color: $darkest-gray;
                background: none;
            }
        }

        &.show {
            .dropdown-toggle {
                color: $darkest-gray;
            }
        }

        .action-icon {
            margin-right: 6px;
        }
    }
}
