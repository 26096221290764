@import "global/constants";

.modal {
    .share-stats-via-email-modal {
        .modal-content {
            width: 396px;
            margin: auto;

            .modal-container {
                font-size: 12px;
                line-height: 16px;
                margin-bottom: 0;
                padding: 32px 24px 24px;
                border: none;
                font-feature-settings: 'tnum' on, 'lnum' on;

                .subtitle {
                    font-weight: 700;
                    font-size: 15px;
                    line-height: 20px;
                    padding-bottom: 4px;
                }

                .redirect-link {
                    font-weight: 700;
                    text-decoration: underline;
                    color: $green;
                    padding-bottom: 15px;

                    .redirect-icon {
                        width: 12px;
                        height: 12px;
                        margin-right: 5.5px;
                    }
                }

                .label {
                    font-weight: 600;
                    padding-bottom: 2px;
                }

                .ant-select {
                    min-height: 32px;
                    font-size: 12px;
                    line-height: 16px;
                    color: $reefer-primary-300;
                }

                .buttons {
                    text-align: right;
                    margin-top: 24px;

                    .cancel-button,
                    .send-button {
                        display: inline;
                        padding: 9px 16px;
                        height: 32px;
                        font-weight: bold;
                        font-size: 11px;
                        line-height: 14px;
                        border-radius: 2px;
                    }

                    .cancel-button {
                        margin-right: 8px;
                        background-color: $white;
                        border-color: $light-border;

                        &:disabled {
                            color: $darkGrey;
                        }
                    }

                    .send-button {
                        &:disabled {
                            opacity: 0.32;
                            background: $green;
                            color: $white;
                        }
                    }
                }
            }
        }
    }
}

.multi-select-custom-label {
    color: $reefer-neutral-600;
}
