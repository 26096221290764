@import "global/constants";

.upload-container {
    .form-label {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        font-weight: 700;

        span {
            font-size: 10px;
            font-weight: 400;
        }

        label {
            margin-bottom: 0;
        }

        .download-label {
            background: none;
            border: none;
            font: inherit;
            cursor: pointer;
            color: $green;
            text-decoration: underline;

            .download-icon {
                margin-right: 4px;
            }
        }
    }

    .upload-region {
        position: relative;
        height: 145px;
        padding: 1.5rem;
        margin-top: 0.25rem;
        margin-bottom: 0.75rem;
        border: 2px dashed $light-border;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .upload-icon {
            color: $light-border;
            font-size: 2rem;
        }

        .upload-text {
            text-align: center;
        }

        .upload-subtext {
            text-align: center;
            padding-bottom: 0.25rem;
            color: $darkGrey;
        }

        .file-input {
            cursor: pointer;
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            width: 100%;
            height: 100%;
            display: block;
        }

        &:hover {
            border: 2px dashed $blue;
        }
    }
}
