@import 'global/constants';

.tag-wrapper { 
    position: relative;
    display: inline;
    margin-right: 0.75rem;

    .tag { 
        margin-bottom: 0.5rem;  
        padding: 0 0.25rem;
        background: $table-header-color;
        border: 1px solid transparent;
        border-radius: 0.125rem;
        width: fit-content;
        display: inline-flex;
    
        .title {
            font-weight: 600;
            margin-right: 0.125rem;
        }
    
        .text {
            word-break: break-word;
            color: $text-dark;
            margin-right: 0.25rem;
        }
        
        .check-icon, .xcheck-icon {
            margin-right: 0.25rem;
        }
    
        .close-icon {
            cursor: pointer;
            color: $darkGrey;
        }

        .check-icon {
            color: $green;
        }

        .xcheck-icon {
            color: $red;
        }
    }

    .tooltip {
        margin-top: 0.125rem; 
    }
}
