@import "global/constants";

.overview-box {
    height: 153px;
    padding-top: 20px;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
        border-color: $blue;
    }
    
    &.simple-container {
        padding-bottom: 0;
        padding-left: 0;
        padding-right: 0;
        margin-bottom: 16px;
    
        .content {
            width: 100%;
    
            .container-title {
                display: flex;
                width: 100%;
    
                .title {
                    margin-left: 0;
                    padding-left: 16px;
                }
                
                .tooltip-container {
                    position: relative;
                    margin-right: 4px;
                    margin-left: 7px;
                    display: inline-block;
                    cursor: pointer;
                    max-height: 12px;
            
                    .simple-tooltip {
                        line-height: 18px;
                    }
            
                    .tooltip-info-icon {
                        font-size: 12px;
                        margin-bottom: 2px;
                        color: $grey;
                    }
                }
            
                .tooltip-container:hover {
                    .tooltip-info-icon {
                        color: $darkest-gray;
                    }
            
                    .simple-tooltip {
                        visibility: visible;
                    }
                }
            }
        }
    
        &.titled-card {
            .title {
                font-style: normal;
                font-weight: bold;
                font-size: 11px;
                line-height: 14px;
                letter-spacing: .08em;
                margin-bottom: 16px;
                text-transform: uppercase
            }
        }
    }

    .status-data {
        .current-active {
            display: flex;
            align-items: center;
            font-style: normal;
            font-weight: 700;
            font-size: 36px;
            margin-bottom: 0;
            padding-left: 16px;
            padding-right: 16px;

            .icon-box {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 20px;
                width: 38px;
                height: 38px;
                margin-right: 8px;
                border-radius: 8px;
            }

            .detention-icon {
                position: absolute;
                right: 20px;
                bottom: 2px;
                font-size: 64px;
                border-radius: 8px;
            }
        }

        .redColorText {
            .icon-box {
                background-color: $light-red;
            }
        }

        .greenColorText {
            .icon-box {
                background-color: $light-green;
            }
        }

        .redColorText,
        .greenColorText {
            .detention-icon {
                color: $table-header-color;
            }
        }

        hr {
            margin: 12px 0 0 0;
        }

        .card-issues {
            padding-top: 4px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .card-data {
                font-size: 16px;
                font-weight: 700;
                margin-bottom: 0;
                width: 100%;
                margin-left: 16px;

                span {
                    font-size: 12px;
                    font-weight: 500;
                    color: $darkGrey;
                }
            }

            .separator {
                border-left: 1px solid $table-header-color;
                height: 24px;
                margin-top: 2px;
            }
        }

        .pin-icon {
            position: absolute;
            top: 18px;
            right: 16px;
            width: 20px;
            height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 12px;
            color: $header-border-color;

            &:hover {
                color: $darkGrey;
            }
        }
    }
}
