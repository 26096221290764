@import 'global/constants';

.modal {
    .error-message-section {
        padding: 5px;

        .centered {
            margin-left: auto;
            margin-right: auto;
            width: fit-content;

            .titleContent {
                font-size: 20px;
                line-height: 20px;
                font-weight: bold;
                margin-bottom: 30px;
            }
        }

        .messageContent {
            font-size: 14px;
            line-height: 16px;
            color: $darkest-gray;
        }

        .errorMessageButton {
            margin-top: 42px;
            width: 100%;
            background-color: $red;
        }
    }
}
