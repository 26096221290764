@import "global/constants";

.expandable-card {
    width: 100%;
    height: 68px;
    margin: 12px 0;
    background-color: $white;
    border: 1px solid $light-border;
    border-radius: 4px;
    cursor: pointer;

    .title {
        height: 68px;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        padding: 18px 9px 18px 24px;
        align-items: center;
        margin: 0;

        @media screen and (max-width: 576px) {
            padding: 18px 0 18px 12px;
        }

        .expand-arrow {
            margin-right: 12px;
            color: $darkGrey;
        }

        .container {
            display: flex;
            align-items: center;
            padding: 0;
            word-wrap: break-word;

            .title-text {
                display: block;
                text-overflow: ellipsis;
                overflow-x: hidden;
                white-space: nowrap;
            }
        }

        .disabled {
            color: $darkGrey;
            margin-left: 5px;
        }

        .column-container {
            padding: 0;
            word-wrap: break-word;

            .column-label {
                font-weight: bold;
                font-size: 12px;
                line-height: 16px;
            }
    
            .column-value {
                font-size: 12px;
                font-weight: normal;
                line-height: 16px;
            }
        }

        .actions {
            text-align: right;

            .action-icon {
                cursor: pointer;
                font-weight: 900;
                font-size: 16px;
                line-height: 16px;
                letter-spacing: 0.33em;
                color: $grey;
                margin: 0 4px;

                &:hover {
                    color: #172437;
                }
            }
        }
    }

    .line {
        width: calc(100% - 48px);
        height: 1px;
        background-color: $lightGrey;
        margin: 0 24px;
    }

    .content {
        width: 100%;
        padding: 24px;
        cursor: default;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .actions {
        .expandable-card-button {
            margin-right: 0;
            margin-left: auto;

            display: flex;
            justify-content: center;
            align-items: center;
            padding: 2px 16px 2px 12px;
            gap: 4px;
        
            width: 197px;
            height: 32px;

            background: $green;
            border-radius: 2px;
            color: $white;
            text-transform: uppercase;
    
            font-style: normal;
            font-weight: 700;
            font-size: 11px;
            line-height: 14px;
            letter-spacing: 0.08em;

            .action-icon {
                height: 12px;
                width: 12px;
                color: $white;

                &:hover {
                    color: $white;
                }
            }
        }
    }
}
