@import "global/constants";

.basic-info {
    margin-top: 6px;
    min-height: 50px;
    display: flex;
    flex-direction: column;
    padding: 24px 24px 20px 24px;

    .heading-info {
        .gathered-info {
            display: inline;
            margin-left: 12px;
            margin-right: 40px;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;

            .icon {
                margin-right: 4px;
            }
        }
    }

    .heading {
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;
        padding: 0;

        .page-title {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
        }

        .trailer-actions {
            display: flex;
        }

        .basic-single-select {
            width: 139px;
            vertical-align: middle;
            margin-right: 8px;

            .select-value__control {
                height: 32px;
                min-height: 32px;
                border-radius: 2px;

                .select-value__value-container {
                    height: 32px;
                    display: flex;
                    justify-content: center;

                    .select-value__placeholder {
                        font-weight: 700;
                        font-size: 11px;
                        line-height: 14px;
                        color: $black;
                        text-transform: uppercase;
                    }

                    .select-value__single-value, .select-value__placeholder, .css-b8ldur-Input {
                        font-style: normal;
                        font-size: 12px;
                        line-height: 16px;
                    }
                }

                .select-value__indicators {
                    height: 32px;
                    .select-value__indicator-separator {
                        display: none;
                    }
                }
            }

            .select-value__menu {
                width: 175px;

                .select-value__menu-list {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 16px;
                }
            }
        }

        .restore-button {
            margin-right: 8px;
        }

        .manifest-button {
            padding: 1px;

            background: $green;
            color: $white;

            border-radius: 2px;
            border-color: transparent;

            font-style: normal;
            font-weight: bold;
            font-size: 11px;
            line-height: 14px;

            .icon-manifest {
                margin-right: 4px;
                padding-top: 1px;
            }

            &.manage {
                width: 170px;
                height: 32px;
            }

            &:hover:enabled {
                color: $background-dark;
            }
        }

        .manifest-badge {
            display: inline-block;
            border-radius: 100px;
            font-size: 12px;
            line-height: 16px;
            padding: 4px 8px;
            color: $white;
            width: fit-content;
            height: 24px;
            margin-left: 8px;
    
            &.active {
                background-color: $green;
            }
    
            &.inactive {
                background-color: $darkGrey;
            }
    
            .icon {
                margin-right: 4px;
            }
        }
    }

    .header-details {
        display: flex;
        justify-content: space-between;

        .header-info {
            display: flex;
            
            .info {
                margin-right: 32px;
                
                .title {
                    font-size: 11px;
                    font-weight: 700;
                    text-transform: uppercase;
                    color: $darkGrey;
                    margin-bottom: 4px;
                    white-space: nowrap;
                }

                .content {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 21px;
                    white-space: nowrap;

                    .link {
                        text-decoration: none;
                    }

                    p {
                        margin-bottom: 0;
                    }

                    .icon {
                        width: 10px;
                        margin-left: 4px;
                        color: $darkGrey;
                    }
                }

                .location{
                    white-space: normal;
                }
            }
        }

        .right {
            .info {
                text-align: right;
                margin-right: 0;
                margin-left: 32px;
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .header-info {
        flex-wrap: wrap;

        .info {              
            margin-bottom: 1rem;
        }
    }

    .right {
        justify-content: end;
    }
}

@media screen and (max-width: 768px) {
    .basic-info {
        .heading {
            flex-direction: column;
            align-items: start;

            .trailer-actions {
                flex-wrap: wrap;
                margin-top: 6px;

                .basic-single-select {
                    width: 170px;
                    margin-bottom: 4px;
                }
            }
        }

        .header-details {
            flex-direction: column;

            .header-info {
                flex-direction: column;
            }

            .right {
                justify-content: start;
    
                .info {
                    text-align: left;
                    margin-left: 0;
                }
            }
        }
    }
} 

@media screen and (max-width: 576px) {
    .basic-info {
        .heading {
            justify-content: start;
            flex-direction: column;

            .manifest-badge {
                margin-left: 0;
            }
        }

        .header-info {
            justify-content: start;
        }
    }
} 
