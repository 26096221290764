@import 'global/constants';

.modal {
    .modify-trailer-modal {
        .modal-content {
            width: 396px;
            margin: auto;

            .modal-container {
                margin-bottom: 0;
                padding: 30px 24px 24px 24px;

                .title {
                    text-transform: uppercase;
                }

                .modal-text {
                    font-size: 12px;
                    line-height: 16px;
                    margin-top: 24px;
                    margin-bottom: 24px;
                }

                .buttons {
                    text-align: right;

                    .cancel-button,
                    .submit-button {
                        height: 32px;
                        text-transform: uppercase;
                        font-weight: bold;
                        font-size: 11px;
                        line-height: 11px;
                        padding: 9px 16px;
                        border-radius: 2px;

                        &:disabled {
                            background: $grey;
                            color: $darkGrey;
                        }
                    }

                    .btn-continue {
                        margin-right: 0;
                    }

                    .cancel-button {
                        margin-right: 10px;
                        background-color: $white;
                        border-color: $light-border;
                    }
                }
            }
        }
    }
}
