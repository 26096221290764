@import "global/constants";

.modal {
    .notificationHistoryModal{
        .modal-content {
            position: absolute;
            max-height: calc(100vh - 12px);

            @media screen and (min-width: 1200px) {
                width: 1020px;
                max-width: 1020px;
                left: -50%;
            }

            @media screen and (min-width: 600px) and (max-width: 1199px) {
                width: 550px;
                max-width: 550px;
            }

            @media screen and (max-width: 599px) {
                width: 350px;
                max-width: 350px;
                left: 10%;
            }

            .notification-history-modal {
                margin-bottom: 0;
            }

            .header {
                margin: 0 auto;
                width: 75%;
                display: flex;
                flex-direction: row;

                .heading-label {
                    display: inline;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 12px;
                    line-height: 16px;
                    flex: 1;
                }

                .info {
                    display: inline;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 16px;
                }
            }

            .modal-body {
                .close-icon {
                    margin-top: -30px;
                    margin-right: -15px;
                    float: right;
                    cursor: pointer;
                    color: $grey;
                }

                .issue-info {
                    overflow: auto;
                    margin-bottom: 15px;

                    .info-label {
                        display: inline;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 14px;
                        line-height: 18px;
                    }

                    .gathered-info {
                        display: inline;
                        margin-left: 12px;
                        margin-right: 40px;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 18px;
                    }
                }

                .notifications-table-div {
                    width: 100%;

                    .dt-align-right {
                        text-align: right;
                    }

                    .dt-align-left {
                        text-align: left;
                    }

                    .dt-align-center {
                        text-align: center;
                    }

                    .table {
                        th {
                            border-bottom: 1px solid $grey;
                        }

                        tbody {
                            tr {
                                height: 40px;
                                border-bottom: 1px solid $light-border;
                                box-sizing: border-box;
                            }

                            tr:hover {
                                background-color: $table-header-color;
                                box-shadow: 0px 4px 12px -4px $light-border;

                                td {
                                    border-top-color: $header-border-color;
                                    border-bottom-color: $header-border-color;

                                    &:first-child {
                                        border-left-color: $header-border-color;
                                    }

                                    &:last-child {
                                        border-right-color: $header-border-color;
                                    }

                                }
                            }
                        }

                        .flex {
                            flex: 1;
                            display: flex;
                            flex-wrap: wrap;
                            align-items: center;
                            white-space: normal;
                        }

                        .info-icon {
                            margin-left: 5px;
                            color: $grey;
                            cursor: pointer;

                            &:hover {
                                color: $background-dark;
                            }
                        }

                        .reason {
                            word-wrap: break-word;
                        }

                        .info-icon.activated {
                            color: $background-dark;
                        }
                    }
                }
            }
        }
    }
}
