@import "global/constants";

.user-card {
    background: $white;
    border: 1px solid $light-border;
    border-top: 4px solid $blue;
    border-radius: 4px;
    margin-bottom: 20px;
    padding: 20px 20px 16px 20px;
    cursor: pointer;

    .name {
        display: flex;
        align-items: center;
        font-weight: bold;
        font-size: 15px;
        line-height: 20px;

        .loader {
            margin-left: 4px;
        }
    }

    .issue-count-heading {
        margin-top: 12px;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: $darkGrey;
    }

    .issue-count {
        margin-top: 12px;
        font-weight: 600;
        display: flex;
        font-size: 20px;
        line-height: 26px;
    }

    &:hover {
        box-shadow: 0 4px 8px $light-border;
        border-left: 1px solid $blue;
        border-right: 1px solid $blue;
        border-bottom: 1px solid $blue;
    }
}
