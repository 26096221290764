@import "global/constants";

.manifest-overview {
    .breadcrumb {
        background-color: transparent;
        font-style: normal;
        font-weight: bold;
        font-size: 11px;
        line-height: 14px;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        margin-top: 11px;

        .breadcrumb-item a {
            color: $darkGrey;
        }

        .active a{
            color: $black;
        }

        .breadcrumb-item + .breadcrumb-item::before {
            content: ">";
        }

        .breadcrumb-item + .breadcrumb-item:active::after {
            content: "<<";
        }

        .breadcrumb-item:active::after {
            content: "<<";
        }

        .svg-icon {
            position: relative;
            top: 5px;
            left: 2px;
        }
    }

    .heading {
        font-style: normal;
        font-weight: 600;
        font-size: 36px;
        line-height: 47px;
        color: $darkest-gray;
        padding-left: 1rem;

        div, svg {
        display: inline;
        }
    }

    .manifest-actions {
        float: right;
        display: inline-flex;

        .active-toggle {
            float: right;
            margin-bottom: 5px;
        }
    }

    .manifest-button {
        margin-bottom: 5px;
        margin-left: 16px;
        padding: 1px;

        color: #fff;

        border-radius: 2px;
        border-color: transparent;
        outline: none;
        border: none;

        font-style: normal;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 11px;
        line-height: 14px;

        .icon-manifest {
            margin-right: 4px;
            padding-top: 1px;
        }

        &.create {
            width: 151px;
            height: 32px;

            background-color: $background-dark;

            &:hover {
                color: $white;
            }
        }

        &.manage {
            width: 100px;
            height: 24px;
            margin-left: 0;

            background-color: $green;
        }
    }

    .manifestTableDiv {
        width: 100%;

        .dt-align-right {
            text-align: right;
        }

        .dt-align-left {
            text-align: left;
        }

        .dt-align-center {
            text-align: center;
        }

        .text-tooltip {
            text-align: center;
            width: 150px;
        }

        .table{
            th {
                background-color: $table-header-color;
                border-top: 1px solid $grey;
                border-right: 1px solid $grey;
            }

            .manifest-trailer-cell {
                display: flex;
                align-items: center;
                justify-content: flex-end;

                .info-icon {
                    cursor: pointer;
                    margin-right: 4px;
                }

                .tooltip-container {
                    position: relative;
                    margin-right: 4px;
                    display: inline-block;
                    cursor: pointer;
    
                    .simple-tooltip {
                        line-height: 18px;
                    }
    
                    .tooltip-info-icon {
                        font-size: 12px;
                        color: $grey;
                    }
                }

                .tooltip-container:hover {
                    .tooltip-info-icon {
                        color: $darkest-gray;
                    }
    
                    .simple-tooltip {
                        visibility: visible;
                    }
                }
            }
        }

        tr {
            height: 40px;
            border: 1px solid $light-border;
            border-bottom: none;
            box-sizing: border-box;

            &:nth-of-type(odd) {
                background: $white;
            }
            &:nth-of-type(even) {
                background: $background-light;
            }
        }

        tr:hover {
            background-color: $table-header-color;
            box-shadow: 0px 4px 12px -4px $light-border;

            td {
                border-top-color: $header-border-color;
                border-bottom-color: $header-border-color;

                &:first-child {
                    border-left-color: $header-border-color;
                }

                &:last-child {
                    border-right-color: $header-border-color;
                }
            }
        }
    }
}
