@import "global/constants";

.custom-chart-container {
    .legend-wrapper {
        display: flex;
        flex-wrap: wrap;
        overflow: hidden;
        margin-top: 30px;
        margin-left: 50px;
        margin-bottom: 20px;
        font-size: 12px;
        line-height: 16px;

        .legend {
            display: flex;
            margin-right: 24px;
            margin-bottom: 2px;
        }

        .right {
            position: absolute;
            right: 10px;
            top: 5px;
        }

        .color-rectangle {
            width: 16px;
            height: 16px;
            border-radius: 4px;
            margin-right: 5px;
        }
    }

    .tooltip-text {
        background: $darkest-gray;
        color: $white;
        text-align: center;
        padding: 5px 10px;
        border-radius: 6px;
    }

    .x-type-label-tick {
        font-size: 12px;
        color: $darkest-gray;
        word-wrap: break-word;

        @media (max-width: 765px), (min-width: 1200px) and (max-width: 1499px) {
            font-size: 10px;
        }

        @media (min-width: 992px) and (max-width: 1199px) {
            font-size: 8px;
        }
    }
}
