@import "global/constants";

.release-notes-page {
    @include page;

    .breadcrumb {
        background-color: transparent;
        font-style: normal;
        font-weight: bold;
        font-size: 11px;
        line-height: 14px;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        margin-top: 32px;
        height: fit-content;
        padding: 0;
        margin-bottom: 16px;

        .breadcrumb-item a {
            color: $darkGrey;
        }

        .active a {
            color: $black;
        }

        .breadcrumb-item + .breadcrumb-item::before {
            content: ">";
        }

        .back-link {
            margin-left: auto;
            text-transform: uppercase;
            font-style: normal;
            font-weight: 900;
            font-size: 11px;
            line-height: 13px;
            letter-spacing: 0.08em;
            color: $darkGrey;
            height: 15px;
        }
    }

    .message-card {
        margin-bottom: 24px;
    }

    .page-title {
        margin-top: 0;
    }

    .publish-new {
        text-align: right;
        margin-top: 2px;

        button {
            background-color: $green;
            color: $white;

            svg {
                margin-right: 6px;
            }
        }

    }

    .release {
        background-color: white;
        box-sizing: border-box;
        border: 1px solid #E2E5E9;
        border-radius: 4px;
        padding: 32px;
        margin: 0 0 32px 0;

        textarea {
            resize: vertical;
        }

        .release-header {
            display: flex;

            .logo {
                width: 36px;
                height: 36px;
                background-color: black;
                border-radius: 4px;
                margin-right: 8px;
            }

            .release-info {
                .date {
                    line-height: 1em;
                    font-size: 20px;
                    font-weight: 700;
                    margin-bottom: 0;
                    margin-top: 1px;
                }

                .version {
                    color: $green;
                    font-size: 12px;
                    margin-bottom: 0;
                    font-weight: 700;
                }
            }

            .edit {
                color: $darkGrey;
                cursor: pointer;
                margin-left: auto;

                &:hover {
                    color: $darkest-gray;
                }
            }

            .delete {
                color: $darkGrey;
                cursor: pointer;
                margin-left: 12px;

                &:hover {
                    color: $darkest-gray;
                }
            }
        }

        .content {
            margin-top: 24px;
            max-width: 560px;
            word-wrap: break-word;
            overflow-wrap: break-word;
            font-size: 12px;

            h1 {
                font-size: 20px;
                font-weight: 700;
                margin-bottom: 12px;
            }

            h2 {
                font-size: 15px;
                font-weight: 700;
                margin-bottom: 4px;
            }

            h3 {
                font-size: 12px;
                font-weight: 700;
            }

            h1, h2, h3 {
                svg {
                    position: relative;
                    top: -.125em;
                    font-size: .75em;
                    margin-right: 10px;
                    color: $green;
                }
            }

            hr {
                margin: 24px 0;
            }

            blockquote {
                background-color: rgba(0, 0, 0, .1);
            }

            table {
                thead,
                tr,
                th,
                td {
                    padding: 4px 8px;
                    border: 1px solid rgba(0, 0, 0, .1);
                }

                thead {
                    tr,
                    tr:last-child {
                        border-left: none;
                        border-right: none;
                        border-bottom: 1px solid rgba(0, 0, 0, .1);
                    }
                }

                tr {
                    border-left: none;
                    border-right: none;
                }

                th,
                td {
                    border-top: none;
                    border-bottom: none;
                }

                tr {
                    &:first-child {
                        border-top-color: transparent;
                    }

                    &:last-child {
                        border-bottom-color: transparent;
                    }
                }

                th,
                td {
                    &:first-child {
                        border-left-color: transparent;
                    }

                    &:last-child {
                        border-right-color: transparent;
                    }
                }
            }
        }

        .actions {
            display: flex;

            button {
                &:first-child {
                    margin-left: auto;
                }

                margin-left: 8px;
            }
        }
    }

    .btn {
        padding: 11px 16px;
        font-style: normal;
        font-size: 11px;
        font-weight: 700;
        line-height: 14px;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        border-radius: 2px;

        &.btn-secondary {
            background-color: transparent;
            color: $darkGrey;
            border: 1px solid #E2E5E9;
        }

        &.btn-continue {
            background-color: $green;
            color: $white;
        }
    }
}
