@import 'global/constants';

.modal {
    .successful-message-section {
        .centered {
            margin-left: auto;
            margin-right: auto;
            width: fit-content;

            .titleContent {
                font-size: 20px;
                line-height: 20px;
                font-weight: bold;
                margin-bottom: 16px;
            }

            .icon {
                width: 32px;
                height: 32px;
                margin-bottom: 20px;
            }
        }

        .messageContent {
            font-size: 14px;
            line-height: 16px;
            color: $darkest-gray;
        }

        .btn-continue {
            &.submit-button {
                margin-top: 32px;
                width: 100%;
            }
        }
    }
}
