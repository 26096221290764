@import "global/constants";

.comments-tooltip {
    .right-arrow-box {
        box-shadow: 0px 8px 24px -8px rgba(3, 13, 29, 0.15);
        position: relative;
        background: $white;
        border: 1px solid $light-border;
        height: 300px;
        width: 260px;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 10px; 
        
        .heading {
            text-align: left;
            padding-left: 0px;
            font-style: normal;
            font-weight: 700;
            font-size: 15px;
            line-height: 20px;
            color: $darkest-gray;
            margin-bottom: 0px;
        }

        .info-label {
            display: inline;
            font-weight: 500;
            line-height: 16px;
            color: $darkest-gray;
            font-size: 12px;
            margin-bottom: 0px;
        }

        .info-label-heading {
            display: inline;
            font-weight: bold;
            margin-bottom: 0px;
            line-height: 16px;
            color: $darkest-gray;
            font-size: 12px;
        }

        .divider {
            margin-top: 10px;
            margin-bottom: 10px;
        }

        .add-comment {
            margin-top: 0px;
            padding-top: 0px;
            position: relative;

            .comment-box {
                resize: none;
                width: 100%;
                height: 55px;
                max-height: 175px;
                border: 1px solid $light-border;
                box-sizing: border-box;
                border-radius: 4px;
                font-size: 12px;
                line-height: 16px;
                overflow: auto;
                padding-bottom: 29px;
                padding-right: 22px;
            }

            .send-button {
                position: absolute;
                bottom: 15px;
                right: 10px;
                color: $grey;
                height: 14px;
                width: 12px;              
            }

            .text-button {
                position: absolute;
                bottom: 15px;
                right: 10px;
                height: 14px;
                width: 12px;              
                color: $darkGrey;
            }

            .hover-button {
                position: absolute;
                bottom: 15px;
                right: 10px;
                height: 14px;
                width: 12px;              
                color: $background-dark;
            }

            .send-button:hover, .text-button:hover {
                color: $background-dark;
                cursor: pointer;
            }
        }

        .comments {
            overflow-y: auto;
            max-height: 44%;
            
            .no-comments {
                padding-top: 21px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;

                .heading, .explanation {
                    font-style: normal;
                    color: $darkGrey;
                    text-align: center;
                    margin-bottom: 6px;
                }

                .icon {
                    color: $grey;
                    padding: 6px;
                    width: 36px;
                    height: 36px;
                }

                .heading {
                    font-weight: bold;
                    font-size: 15px;
                    line-height: 20px;
                }

                .explanation {
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 16px;                    
                }
            }

            .comment {
                margin-top: 7px;

                .info-box {
                    display: flex;

                    .circle {
                        height: 30px;
                        width: 30px;
                        margin-right: 7px;
                        margin-top: 1px;
                        background-color: $circle-background;
                        border-radius: 50%;
                        display: inline-block;

                        .initials {
                            font-weight: bold;
                            font-size: 12px;
                            line-height: 15px;
                            text-align: center;
                            margin-top: 25%;
                            color: $darkest-gray;
                        }

                    }

                    .text {
                        display: inline-block;
                        vertical-align: top;
                        padding-top: 3px;
                        flex: 1;

                        .name, .time {
                            font-weight: 500;
                            line-height: 12px;
                            color: $darkest-gray;
                            margin-bottom: 3px;
                        }

                        .name {
                            display: flex;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: bold;

                            .you {
                                font-size: 10px;
                                color: $grey;
                                font-weight: 500;
                            }

                            .comment-delete-icon {
                                color: $grey;
                                font-style: normal;
                                font-size: 12px;
                                margin-left: auto;
                                margin-right: 17px;

                                &:hover {
                                    color: $background-dark;
                                    cursor: pointer;
                                }
                            }
                        }

                        .time {
                            font-style: italic;
                            font-size: 10px;
                            color: $darkGrey;
                        }
                    }
                }

                .comment-text, .comment-deleted-text, .comment-deleted-date {
                    margin-bottom: 0px;
                    margin-top: 5px;
                    word-wrap: break-word;
                    margin-left: 3px;
                    color: $darkGrey;
                    font-size: 12px;
                    line-height: 16px;
                    font-style: normal;
                    font-weight: 500;

                    .name {
                        font-weight: bold;
                    }
                }

                .comment-deleted-date {
                    color: $grey;
                }

                .delete-prompt {
                    font-size: 12px;
                    font-style: normal;
                    font-weight: bold;
                    margin-bottom: 0px;
                }

                .delete-confirmation {
                    margin-bottom: 0px;
                    word-wrap: break-word;
                    color: $darkGrey;
                    font-size: 12px;
                    line-height: 16px;
                    font-style: normal;
                    font-weight: 500;
                }

                .buttons {
                    margin-top: 10px;
                    text-align: right;

                    .comment-prompt-button {
                        display: inline;
                        text-transform: uppercase;
                        width: 68px;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 11px;
                        line-height: 14px;
                        margin-right: 10px;
                    }
                }
            }
        }

        &.read-only {
            height: unset;
            max-height: 300px;

            .comments {
                max-height: 210px;

                .no-comments {
                    padding-bottom: 33px;
                }
            }
        }
    }

    .right-arrow-box:after, .right-arrow-box:before {
        left: 100%;
        top: 90%;
        border: solid transparent;
        content: "";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;    
    }

    .right-arrow-box:after {
        border-color: rgba(136, 183, 213, 0);
        border-left-color: $white;
        border-width: 10px;
        margin-top: -10px;    
    }

    .right-arrow-box:before {
        border-color: rgba(194, 225, 245, 0);
        border-left-color: $light-border;
        border-width: 11px;
        margin-top: -11px;    
    }
}
