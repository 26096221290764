@import 'global/constants';

.modal {
    .manage-manifest-modal {
        .modal-content {
            width: 396px;
            margin: auto;

            .modal-container {
                margin-bottom: 0;
                padding: 30px 24px 24px 24px;

                .message-card {
                    margin-bottom: 12px;
                }

                .title {
                    text-transform: uppercase;
                }

                .description {
                    font-size: 12px;
                    line-height: 16px;
                    color: #030D1D;
                }

                .manifest-section, .trailer-section, .tracking-section {
                    .heading {
                        font-size: 15px;
                        font-weight: 700;
                        line-height: 20px;
                    }

                    .prompt {
                        display: block;
                        font-style: normal;
                        font-size: 12px;
                        line-height: 16px;
                        margin-bottom: 4px;
                        margin-top: 10px;

                        .asterisk {
                            color: #D3424E;
                        }

                        .unit {
                            color: #828A95;
                        }

                        .tooltip-container {
                            display: inline-block;
                            cursor: pointer;
                            margin-left: 4px;

                            .icon {
                                color: #D0D2DD;
                            }

                            &:hover {
                                .simple-tooltip {
                                    visibility: visible;
                                }

                                .icon {
                                    color: #030D1D;
                                }
                            }
                        }
                    }

                    .error-message {
                        font-size: 12px;
                        line-height: 16px;
                        color: #D3424E;
                    }
                }

                .manifest-section {
                    .manifest-id-input {
                        width: 209px;
                        background-color: transparent;
                        padding: 5px 10px;
                        font-size: 12px;
                        height: 32px;
                        outline: none;
                        border: 1px solid $header-border-color;
                        box-sizing: border-box;
                        border-radius: 4px;

                        &.disabled {
                            background: #F4F5FA;
                            border: 1px solid #E2E5E9;
                            opacity: 1;
                        }

                        &.error {
                            border: 1px solid #D3424E;
                        }
                    }

                    .helper {
                        font-size: 12px;
                        line-height: 16px;
                        color: #828A95;
                        margin-left: 8px;
                        margin-right: 8px;
                    }

                    .generate-id-button {
                        letter-spacing: 0;
                        display: inline;
                        height: 32px;
                        background-color: $background-dark;
                        font-size: 11px;
                        font-weight: 700;
                        text-transform: uppercase;
                        border-radius: 2px;
                        padding: 9px 16px;
                        outline: none;
                        border: none;

                        &:disabled {
                            background: #D0D2DD;
                            color: #828A95;
                        }
                    }

                    .loader {
                        div, svg {
                            display: inline;
                        }

                        span {
                            margin-left: 4px;
                            font-size: 12px;
                        }
                    }

                    .message {
                        margin-top: 8px;
                    }

                    .basic-single-trailer {
                        vertical-align: middle;

                        &.select-trailer--is-disabled {
                            .select-trailer__control {
                                background: #F4F5FA;
                            }

                            .select-trailer__single-value {
                                color: rgba(0, 0, 0, 0.85);
                            }

                            .css-b8ldur-Input {
                                font-weight: 400;
                            }

                            .select-trailer__indicators {
                                display: none;
                            }
                        }

                        .select-trailer__control {
                            height: 32px;
                            min-height: 32px;

                            .select-trailer__value-container {
                                height: 32px;

                                .select-trailer__single-value, .select-trailer__placeholder, .css-b8ldur-Input {
                                    font-style: normal;
                                    font-size: 12px;
                                    line-height: 16px;
                                }
                            }

                            .select-trailer__indicators {
                                height: 32px;

                                .select-trailer__indicator-separator {
                                    display: none;
                                }
                            }
                        }

                        .select-trailer__menu {
                            .select-trailer__menu-list {
                                font-style: normal;
                                font-weight: 500;
                                font-size: 12px;
                                line-height: 16px;
                            }
                        }
                    }
                }

                .trailer-section {
                    margin-top: 32px;

                    .trailer-settings {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;

                        .minimum, .maximum, .mode, .power {
                            display: flex;
                            flex-direction: column;
                        }

                        .minimum, .maximum {
                            .min-temp-input,
                            .max-temp-input {
                                width: 168px;
                                height: 32px;
                                background-color: transparent;
                                padding: 5px 10px;
                                font-size: 12px;
                                outline: none;
                                border: 1px solid $header-border-color;
                                box-sizing: border-box;
                                border-radius: 4px;

                                &.error {
                                    border: 1px solid #D3424E;
                                }

                                &:disabled {
                                    background: $background-light;
                                    border: 1px solid $light-border;
                                    color: $darkGrey;
                                }
                            }
                        }

                        .mode, .power {
                            .basic-single-select {
                                width: 168px;
                                vertical-align: middle;

                                .select-value__control {
                                    height: 32px;
                                    min-height: 32px;

                                    .select-value__value-container {
                                        height: 32px;

                                        .select-value__single-value, .select-value__placeholder, .css-b8ldur-Input {
                                            font-style: normal;
                                            font-size: 12px;
                                            line-height: 16px;
                                        }
                                    }

                                    .select-value__indicators {
                                        height: 32px;
                                        .select-value__indicator-separator {
                                            display: none;
                                        }
                                    }
                                }

                                .select-value__menu {
                                    .select-value__menu-list {
                                        font-style: normal;
                                        font-weight: 500;
                                        font-size: 12px;
                                        line-height: 16px;
                                    }
                                }
                            }
                        }
                    }
                }

                .tracking-section {
                    margin-top: 32px;
                    display: flex;
                    flex-direction: row;

                    .pill-container {
                        height: 16px;
                        margin-top: 3px;
                        margin-left: 8px;
                    }
                }

                .buttons {
                    margin-top: 20px;
                    text-align: right;

                    .cancel-button, .submit-button {
                        display: inline;
                        height: 32px;
                        width: 80px;
                        text-transform: uppercase;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 11px;
                        line-height: 14px;
                        padding: 0px;

                        &:disabled {
                            background: #D0D2DD;
                            color: #828A95;
                        }
                    }

                    .cancel-button {
                        margin-right: 10px;
                        color: #828A95;
                    }
                }
            }
        }
    }
}
