@import "global/constants.scss";
.dataTableDiv {
  width: 100%;
  display: inline-block;

  .tooltip-text {
    pointer-events: none;
  }

  .tooltip-notifications, .seen-by, .tooltip-text, .tooltip-detention, .tooltip-inactivity, .comments-by, .tooltip-text, .tooltip-low-fuel-level, .tooltip-location, .tooltip-resolved, .snooze-note {
    position: fixed;
    z-index: 9999;
    display: none;
  }

  .dataTables_wrapper{
    &.no-footer {
      .dataTables_scrollBody {
        border-bottom: 1px solid $light-border;
      }
    }
  }

  table.dataTable.cell-border tbody td {
    border-top: 1px solid $light-border;
    border-right: 1px solid $light-border;
  }

  .table {
    table-layout: fixed;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    align-items: center;
    color: $darkest-gray;
    margin-bottom: 0rem;
    border-bottom-width: 0px;
    width: 100%;

    thead {

      span {
        &.arrow-hack {
          margin-left: 3px;
        }
        &.asc {
          background: url("../../files/sort-icon/sort_asc.png") no-repeat;
          background-size: 9px 14px;
          background-position: 100% 50%;
          padding-right: 15px;
        }
        &.desc {
          background: url("../../files/sort-icon/sort_desc.png") no-repeat;
          background-size: 9px 14px;
          background-position: 100% 50%;
          padding-right: 15px;
        }
        &.sort {
          background: url("../../files/sort-icon/sort_default.png") no-repeat;
          background-size: 9px 14px;
          background-position: 100% 50%;
          padding-right: 15px;
        }
      }

      .sorting,
      .sorting_asc,
      .sorting_desc {
        min-width: 80px;
        background-image: none !important;
      }

      th {
        font-size: 14px;
        font-weight: bold;
        border-bottom: 0px;
        border-top: none;
        white-space: nowrap;
        padding: 10px;
        padding-right: 15px;
      }
    }

    .clickable {
      cursor: pointer;
    }

    td {
      white-space: nowrap;
      padding: 10px;
      padding-right: 15px;
      .flex-always {
        white-space: nowrap;
        text-overflow: ellipsis;
        display: inline-flex;
        flex-wrap: nowrap;
        flex-grow: 1;
      }
      @media screen and (min-width: 1400px) {
        .flex {
        white-space: nowrap;
        text-overflow: ellipsis;
        display: inline-flex;
        flex-wrap: nowrap;
        flex-grow: 1;
      }
     }

      .no-flex {
        white-space: normal;
        word-wrap: break-word;
      }

      .seen-icon.light, .icon, .info-icon, .disabled-snooze-icon.light, .comment-icon.light {
        color: $grey;
      }

      .seen-icon.medium, .snooze-icon.medium, .comment-icon.medium {
        color: $darkGrey;
      }

      .seen-icon:hover, .info-icon:hover, .snooze-icon:hover, .disabled-icon:hover, .comment-icon:hover {
        color: $background-dark;
      }

      .seen-icon.activated, .info-icon.activated, .snooze-icon.activated, .disabled-icon.activated, .comment-icon.activated {
        color: $background-dark;
      }
    }
  }

  [type="search"] {
    width: 113px;
    height: 32px;
    left: 540px;
    top: 422px;

    background: $background-light;
    border: 1px solid $light-border;
    box-sizing: border-box;
    border-radius: 4px;
  }

  input[type="radio"] {
    width: 25px;
    vertical-align: baseline;
    box-sizing: border-box;
    display: inline-block;

    label {
      width: calc(100% - 25px);
      vertical-align: baseline;
      padding-left: 16px;
      box-sizing: border-box;
      display: inline-block;
    }
  }
}
