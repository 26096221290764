.fleet-select {
    margin-bottom: 8px;

    .fleet-select__control {
        height: 32px;
        min-height: 32px;

        .fleet-select__single-value, .fleet-select__placeholder, .css-b8ldur-Input {
            font-style: normal;
            font-size: 12px;
            line-height: 16px;
        }

        .fleet-select__indicators {
            height: 32px;

            .fleet-select__indicator-separator {
                display: none;
            }
        }
    }

    .fleet-select__menu-list {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
    }
}
