@import "global/constants";

.assignmentDetails {
    padding-bottom: 2px;

    .breadcrumb {
        background-color: transparent;
        font-style: normal;
        font-weight: bold;
        font-size: 11px;
        line-height: 14px;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        margin-top: 11px;
        height: fit-content;
        padding: 0rem;
        margin-bottom: 0px;

        .breadcrumb-item a {
            color: $darkGrey;
        }

        .active a {
            color: $black;
        }

        .breadcrumb-item + .breadcrumb-item::before {
            content: ">";
        }

        .breadcrumb-item + .breadcrumb-item:active::after {
            content: "<<";
        }
    }

    .back-link {
        margin-left: auto;
        text-transform: uppercase;
        font-style: normal;
        font-weight: 900;
        font-size: 11px;
        line-height: 13px;
        letter-spacing: 0.08em;
        color: $darkGrey;
        height: 15px;
    }

    .orderHeading {
        font-style: normal;
        font-weight: 600;
        font-size: 36px;
        line-height: 47px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .page-title {
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            div, svg {
                display: inline;
            }

            .manifest-badge {
                display: inline-block;
                border-radius: 100px;
                font-size: 12px;
                line-height: 16px;
                padding: 4px 8px;
                color: #FFFFFF;
                margin: 0 12px;
                width: fit-content;
                height: 24px;

                &.active {
                    background-color: #19BD8C;
                }

                &.inactive {
                    background-color: #828A95;
                }

                .icon {
                    margin-right: 4px;
                }
            }
        }

        .manifest-button {
            padding: 1px;

            background: $green;
            color: #fff;

            border-radius: 2px;
            border-color: transparent;

            font-style: normal;
            font-weight: bold;
            font-size: 11px;
            line-height: 14px;

            .icon-manifest {
                margin-right: 4px;
                padding-top: 1px;
            }

            &.manage {
                width: 166px;
                height: 32px;
            }

            &:hover {
                color: #172437;
            }
        }
    }

    .trailerSettingsDetails {
        margin-top: 0px;
    }

    .snooze-icon {
        width: 16px;
    }

    .snooze-icon, .seen-icon, .info-icon, .comment-icon, .resolve-icon {
        cursor: pointer;
    }

    .snooze-icon.medium, .comment-icon.medium {
        color: $darkGrey;
    }

    .snooze-icon.medium:hover, .disabled-icon-snooze.light:hover, .disabled-icon:hover, .comment-icon.light:hover, .comment-icon.medium:hover, .resolve-icon:hover {
        color: $background-dark;
        fill: $background-dark;
    }

    .disabled-icon-snooze.light, .comment-icon.light, .resolve-icon {
        color: $grey;
    }

    .disabled-icon > path {
        color: $disabled-snooze-color;
        fill: $disabled-snooze-color;
    }

    .disabled-icon:hover > path {
        fill: $background-dark;
    }

    .arrow-icon {
        width: 15px;
        height: 30px;
    }

    .link {
        text-decoration: underline;
        cursor: pointer;
    }

    table {
        tbody {
            .manager-notified, .operations-notified {
                .notification-level, .notification-info {
                    display: inline;
                }
            }
        }
    }
}
