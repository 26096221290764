@import "global/constants";

.modal {
    .add-user-modal {
        .modal-content {
            width: 396px;
            margin: auto;

            .modal-container {
                font-size: 12px;
                line-height: 16px;
                margin-bottom: 0;
                padding: 32px 24px 24px;
                border: none;

                .label {
                    font-weight: 600;
                    margin-top: 12px;
                    margin-bottom: 2px;
                }

                .custom-input {
                    width: 100%;
                    padding: 8px;
                    font-size: 12px;
                    height: 32px;
                    outline: none;
                    border: 1px solid $header-border-color;
                    border-radius: 4px;
                    
                    &:disabled {
                        color: $darkGrey;
                    }
                }

                .buttons {
                    text-align: right;
                    margin-top: 24px;

                    .cancel-button, .submit-button {
                        display: inline;
                        padding: 9px 16px;
                        height: 32px;
                        font-weight: bold;
                        font-size: 11px;
                        line-height: 14px;
                        border-radius: 2px;

                        &:disabled {
                            color: $darkGrey;
                        }
                    }

                    .cancel-button {
                        margin-right: 8px;
                        background-color: $white;
                        border-color: $light-border;
                    }
                }
            }
        }
    }
}
