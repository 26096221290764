@import "global/constants";

.modal {
    .manage-email-updates-modal {
        .modal-content {
            width: 396px;
            margin: auto;

            .modal-container {
                font-size: 12px;
                line-height: 16px;
                margin-bottom: 0;
                padding: 32px 24px 24px;
                border: none;
                font-feature-settings: 'tnum' on, 'lnum' on;

                .subtitle {
                    font-weight: 700;
                    font-size: 15px;
                    line-height: 20px;
                }

                .redirect-link {
                    font-weight: 700;
                    text-decoration: underline;
                    color: $green;
                    padding-bottom: 15px;

                    .redirect-icon {
                        width: 12px;
                        height: 12px;
                        margin-right: 5.5px;
                    }
                }

                .label {
                    font-weight: 600;
                    padding-bottom: 2px;
                }

                .label-tooltip {
                    display: flex;
                    align-items: center;
                    padding-bottom: 2px;

                    .label {
                        padding-bottom: 0px;
                    }

                    .tooltip-container {
                        position: relative;
                        margin-right: 4px;
                        margin-left: 7px;
                        display: inline-block;
                        cursor: pointer;
                        max-height: 12px;

                        .simple-tooltip {
                            line-height: 18px;
                        }

                        .tooltip-info-icon {
                            font-size: 12px;
                            margin-bottom: 2px;
                            color: $grey;
                        }
                    }

                    .tooltip-container:hover {
                        .tooltip-info-icon {
                            color: $darkest-gray;
                        }

                        .simple-tooltip {
                            visibility: visible;
                        }
                    }
                }

                .manage-email-dropdown {
                    vertical-align: middle;
                    margin-bottom: 15px;

                    &.select-option--is-disabled {
                        .select-option__control {
                            background: #F4F5FA;
                        }

                        .select-option__single-value {
                            color: rgba(0, 0, 0, 0.85);
                        }

                        .css-b8ldur-Input {
                            font-weight: 400;
                        }

                        .select-option__indicators {
                            display: none;
                        }
                    }

                    .select-option__control {
                        height: 32px;
                        min-height: 32px;

                        .select-option__value-container {
                            height: 32px;

                            .select-option__single-value,
                            .select-option__placeholder,
                            .css-b8ldur-Input {
                                font-style: normal;
                                font-size: 12px;
                                line-height: 16px;
                            }
                        }

                        .select-option__indicators {
                            height: 32px;

                            .select-option__indicator-separator {
                                display: none;
                            }
                        }
                    }

                    .select-option__menu {
                        .select-option__menu-list {
                            font-style: normal;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 16px;
                        }
                    }
                }

                .time-dropdown {
                    padding-bottom: 15px;
                }

                .date-time-container {
                    display: flex;
                    gap: 12px;
                    padding-bottom: 9px;

                    .day-container {
                        width: 50%;
                        display: block;

                        &.day-interval {
                            display: none;
                        }

                        &.week-interval {
                            display: block;
                        }

                        &.month-interval {
                            display: none;
                        }
                    }

                    .date-container {
                        width: 50%;

                        &.day-interval {
                            display: none;
                        }

                        &.week-interval {
                            display: none;
                        }

                        &.month-interval {
                            display: block;
                        }
                    }

                    .time-container {
                        padding-bottom: 15px;

                        &.day-interval {
                            width: 100%;
                        }

                        &.week-interval {
                            width: 50%;
                        }

                        &.month-interval {
                            width: 50%;
                        }
                    }

                    .manage-email-updates-date-picker {
                        width: 100%;
                    }
                }

                .enable-email-updates-action-container {
                    display: flex;
                    padding-bottom: 4px;
                    align-items: center;

                    .subtitle {
                        padding-right: 8px;
                        padding-bottom: 0px;
                    }
                }

                .buttons {
                    text-align: right;
                    margin-top: 24px;

                    .cancel-button,
                    .confirm-button {
                        display: inline;
                        padding: 9px 16px;
                        height: 32px;
                        font-weight: bold;
                        font-size: 11px;
                        line-height: 14px;
                        border-radius: 2px;
                    }

                    .cancel-button {
                        margin-right: 8px;
                        background-color: $white;
                        border-color: $light-border;

                        &:disabled {
                            color: $darkGrey;
                        }
                    }

                    .confirm-button {
                        &:disabled {
                            opacity: 0.32;
                            background: $green;
                            color: $white;
                        }
                    }
                }
            }
        }
    }
}

.ant-picker-footer {
    display: none;
} 
