@import "global/constants";

.copy {
    cursor: pointer;
    color: $darkGrey;
    margin-right: 5px;
    margin-left: 5px;

    &:hover {
        color: $darkest-gray;
    }

    &.active {
        color: $green;
    }
}
