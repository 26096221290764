.ant-select {
    display: flex;
    align-items: center;
    border: 1px solid #E4E7EB;
    box-sizing: border-box;
    border-radius: 4px;
    width: 100%;
    min-height: 40px;
    cursor: pointer;
    margin-bottom: 12px;

    &.small {
        height: 32px;
    }

    &:hover {
        box-shadow: none; 
        border: 1px solid #CBD2D9;
        box-sizing: border-box;
        border-radius: 4px;
    }
}

.ant-select-focused {
    border: 1px solid #2186EB;;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-select-selector {
    background-color: unset;
    border: none;
    border-radius: 0;
    transition: none;
    height: inherit;
    align-items: center;
    width: 100%;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled),
.ant-select-item-option-active:not(.ant-select-item-option-disabled),
.ant-select-item-option-active,
.ant-select-item-option-selected {
    background-color: #F5F7FA;
    font-weight: 600;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    box-shadow: none;
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    cursor: pointer;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    height: 100%;
}

.ant-select-single .ant-select-selector .ant-select-selection-item::after {
    content: none;
}

.ant-select-item-option-content {
    overflow: initial;
    white-space: initial;
    text-overflow: initial;
}

.ant-select-selection-item .select-option {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
}
