@import "global/constants";

.account{
  cursor: pointer;
  font-style: normal;
  float: right;
  .circle {
    height: 32px;
    width: 32px;
    margin: 14px;
    background-color: $circle-background;
    border-radius: 50%;
    display: inline-block;

    .initials {
      font-size: 12px;
      line-height: 14px;
      margin: 25%;
      color: $darkest-gray;
    }

    .visible {
      display: block;

      .submenu {
        cursor: default;
        height: 144px;
        margin-right: 14px;
        padding-left: 12px;
        background: white;
        border: 1px solid $light-border;
        box-sizing: border-box;
        border-radius: 4px;
        z-index: 9999;
        position: absolute;
        right: 24px;
        top: 50px;

        .inside {
          margin-right: 12px;
          clear: right;
          float: right;
          color: $darkest-gray;
        }
        .name {
          font-weight: normal;
          font-size: 15px;
          line-height: 20px;

        }
        .username {
          font-weight: normal;
          font-size: 12px;
          line-height: 16px;
          color: $darkest-gray;
        }
        
        .logout {
          margin: 12px;

          .icon {
            font-weight: 900;
            font-size: 16px;
            line-height: 18px;
            letter-spacing: 0.08em;
            text-transform: uppercase;   
          }

          .logout-button {
            font-weight: bold;
            font-size: 11px;
            line-height: 14px;
            letter-spacing: 0.08em;
            text-transform: uppercase;
            display: inline-flex;
            align-items: center; 
            border: none;
            background-color: transparent;
          }
        }
      }
    }
    .hidden {
      display: none;
    }
  }
}
