@import "global/constants";

.active-selection {
  display: inline-block;
  .info-label {
    margin-right: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 32px;
    float: left;
  }

  .disabled {
    color: $header-border-color;
  }

  .buttons {
    border: 1px solid $light-border;
    box-sizing: border-box;
    border-radius: 4px;
    float: left;

    .left {
      border-radius: 4px 0 0 4px;
    }

    .right {
      border-radius: 0 4px 4px 0;
    }
    
    .active {
      background-color: white;
      color: $darkest-gray;
      float: left;
      height: 30px;
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      margin: 0 auto;
    }

    .inactive {
      background-color: $table-header-color;
      color: $grey;
      float: left;
      height: 30px;
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      margin: 0 auto;
    }

    .active:disabled,
    .inactive:disabled{
      opacity: 30%;
    }

    .btn {
      padding: 8px 12px;
    }

    .btn:focus {
      outline: none;
      box-shadow: none;
    }

  }

}
