@import "~bootstrap/scss/bootstrap";
@import 'global/constants';
@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@400;600;700&display=swap');
$fa-font-path: "~font-awesome/fonts";
@import "~font-awesome/scss/font-awesome";

body {
  background-color: $background-light;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.main {
  margin-left: $nav-bar-width;
  font-family: 'HK Grotesk', 'Barlow', sans-serif;

  @media (min-width: 992px) {
    .application-content {
      padding: 0 40px;
    }
  }
}
