@import "global/constants";

.message-card {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    border: 1px solid transparent;

    .icon {
        margin-right: 6px;
        margin-left: 6px;
    }

    &.regular {
        font-size: 14px;
        padding: 3px;

        .icon {
            font-size: 14px;
        }
    }

    &.small {
        font-size: 12px;
        padding: 3px;

        .icon {
            font-size: 12px;
        }
    }

    &.error {
        background: rgba(211, 66, 78, 0.08);
        border-color: rgba(211, 66, 78, 0.4);

        .icon {
            color: $red;
        }
    }

    &.warning {
        background: rgba(242, 124, 70, 0.08);
        border-color: rgba(242, 124, 70, 0.4);

        .icon {
            color: #F27C46;
        }
    }

    &.info {
        background: rgba(33, 134, 235, 0.08);
        border-color: rgba(33, 134, 235, 0.4);;

        .icon {
            color: #2186EB;
        }
    }
}
