@import "global/constants";

.notifications-management {
    padding-top: 8px;
    color: $darkest-gray;
    padding-bottom: 20px;

    .header {
        font-weight: bold;
        font-size: 20px;
        line-height: 26px;
        background-color: transparent;
    }

    .expand {
        font-size: 12px;
        line-height: 16px;
        cursor: pointer;
        display: flex;
        align-items: center;
        
        .expand-arrow {
            margin-left: 5px;
        }
    }
}

.expandable-card-title-settings {
    display: flex;
    align-items: center;
    gap: 8px;
}

.schedule-notification-settings-box  {
    width: 35%;
    align-self: flex-start;
    border: 1px dashed $reefer-info-500;
    border-radius: 4px;
    padding: 20px 24px 24px 24px;
    margin-top: 0;
    font-size: 12px;
    line-height: 16px;

    .schedule-notification-settings-title {
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 24px;
    }

    @media screen and (max-width: 1200px) {
        margin-top: 20px;
    }
}

.default-schedule-notification-settings-box {
    width: 100%;
    text-align: center;

    .info-icon {
        color: $reefer-info-500;
        width: 32px;
        height: 32px;
    }

    .title {
        font-size: 20px;
        font-weight: 700;
    }

    .subtitle {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
    }
}
