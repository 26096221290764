@import "global/constants";

.multi-select {
    position: relative;
    padding: 7px;
    color: $darkest-gray;
    background-color: $white;
    border: 1px solid $grey;
    border-radius: 4px;

    &.borderless {
        border: none;

        .select-title {
            font-weight: bold;
        }
    }

    &.searchable {
        display: inline-block;
        width: 100%;
    }

    .select-title {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;

        .select-label {
            display: flex;
            flex-wrap: wrap;
            gap: 4px;

            .search-container {
                color: $reefer-primary-300;
                display: flex;

                .select-search {
                    border: none;
                    outline: none;
                }
            }
        }

        .select-down-arrow {
            color: $darkest-gray;
            font-size: 16px;
            margin-left: 12px;
            margin-right: 4px;
        }

        &.placeholder {
            color: $darkGrey;
        }
    }

    .select-menu {
        position: absolute;
        width: 100%;
        top: calc(100% + 4px);
        right: 0;
        background-color: $white;
        border: 1px solid $grey;
        border-radius: 4px;
        font-size: 12px;
        line-height: 16px;
        z-index: 100;

        &.search-select-menu {
            overflow: scroll;
            max-height: 200px;
        }

        .select-item {
            cursor: pointer;
            display: flex;
            padding: 8px 16px;

            &.disabled {
                cursor: default;
                opacity: 0.2;

                &:hover {
                    background-color: $white;
                }
            }

            &:first-child {
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
            }

            &:last-child {
                border-bottom-left-radius: 4px;
                border-bottom-right-radius: 4px;
            }

            .select-checkbox-input {
                display:none;
                cursor: pointer;
                margin-right: 8px;

                + .select-checkbox:before {
                    font-family: FontAwesome;
                    content: "\f0c8";
                    color: $grey;
                    letter-spacing: 8px;
                }

                &:checked + .select-checkbox:before {
                    content: "\f14a";
                    color: $reefer-info-400;
                }
            }

            &:hover {
                background-color: $background-light;
            }
        }
    }
}
