@import "global/constants";

body {
    background-color: $white;
}

.statistics-summary-preview {
    .info-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 4px 8px;
        background: $reefer-info-200;
        border: 1px solid $reefer-info-300;

        font-weight: 500;
        font-size: 15px;
        line-height: 24px;

        .info-icon {
            color: $reefer-info-500;
            padding-right: 9px;
        }

        .info-text {
            width: 100%;
        }

        .close-icon {
            color: $reefer-neutral-600;
            width: 15px;
            height: 12px;
        }
    }

    .summary-container {
        width: 600px;
        background: white;
        border: 1px solid $reefer-neutral-400;

        .summary-header-text {
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            color: $brand-black-01;
        }

        .summary-header {
            display: flex;
            align-items: center;
            background: $background-dark;
            height: 80px;
            padding: 40px;

            font-weight: 700;
            font-size: 20px;
            line-height: 26px;
            color: white;

            .summary-header-prs-logo {
                height: 40px;
                margin-right: 0;
                margin-left: auto;
            }
        }

        .summary-details {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 40px;

            .summary-details-header {
                display: flex;
                align-items: center;
                gap: 10px;
                font-weight: 700;

                .summary-details-date {
                    font-size: 11px;
                    line-height: 12px;
                    letter-spacing: 0.04em;
                    color: $prs-blue;
                }
            }

            .summary-details-open-in-app-btn {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 8px 16px;
                gap: 4px;

                background: $prs-blue;
                border-radius: 4px;

                font-weight: 700;
                font-size: 12px;
                line-height: 16px;
                letter-spacing: 0.25px;
                text-decoration: none;
                color: $white;
            }
        }

        .summary-issues-info-count-box {
            display: flex;
            justify-content: space-around;
            align-items: center;
            text-align: center;
            height: 108px;
            background: $background-light;
            border-radius: 4px;
            margin: 0 40px 24px;

            .count-box {
                .count-text {
                    font-weight: 700;
                    font-size: 32px;
                    line-height: 38px;
                    letter-spacing: 0.25px;
                    color: $prs-blue;
                }

                .issue-desc-text {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 14px;
                    letter-spacing: 0.25px;
                    color: $brand-black-01;
                    padding-top: 8px;
                }
            }
        }

        .summary-issues-info {
            padding: 0 40px 40px;

            .issue-count-box {
                display: flex;
                gap: 12px 19px;
                flex-wrap: wrap;
                margin-top: 12px;
                margin-bottom: 24px;

                .issue-count-card {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 8px 10px;
                    background: $background-light;
                    border-radius: 4px;
                    width: 160px;
                    gap: 10px;

                    &.inactive-issue {
                        position: relative;
                    }

                    .issue-icon {
                        font-weight: 900;
                        font-size: 12px;
                        line-height: 12px;
                        color: $prs-blue;

                        &.inactive-issue {
                            position: absolute;
                            left: 10px;
                            width: 18px;
                        }
                    }

                    .issue-text {
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 15px;
                        width: 100%;
                        color: $brand-black-01;

                        &.inactive-issue {
                            position: absolute;
                            left: 32px;
                            width: auto;
                        }
                    }

                    .issue-count {
                        font-weight: 700;
                        font-size: 12px;
                        line-height: 15px;

                        &.inactive-issue {
                            position: absolute;
                            right: 10px;
                        }
                    }
                }
            }

            .summary-other-stats-box {
                margin-top: 12px;
                margin-bottom: 24px;

                .stats-box {
                    display: flex;
                    align-items: center;
                    gap: 4px;
                    padding-bottom: 8px;

                    .count-text {
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 24px;
                        color: $prs-blue;
                    }

                    .desc-text {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 15px;
                        color: $darkGrey;
                    }
                }
            }

            .summary-notice {
                margin-top: 8px;
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 150%;
                letter-spacing: 0.25px;
                color: $brand-black-01;

                .main-page-link {
                    color: $prs-blue;
                    text-decoration: none;
                }
            }
        }
    }
}
