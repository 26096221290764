@import "global/constants";

.header {
    background-color: $white;

    .search {
        height: 60px;
    }

    .user-settings {
        display: flex;
        align-items: center;
        height: 60px;

        .multi-select-text {
            height: 28px;
        }

        .select-title {
            letter-spacing: 0.88px;
            text-transform: uppercase;
        }

        .select-menu {
            width: 162px;
        }
    }
}
